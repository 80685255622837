.entry-grid-container,
.view-entry-grid-container {
  display: grid;
  gap: 20px;
  background-color: black;
  padding: 20px;
  color: white;
  height: 100%;
}

.entry-grid-container {
  grid-template-columns: repeat(4, 1fr);
}

.view-entry-grid-container {
  grid-template-columns: repeat(3, 1fr);
}

.grid-section {
  background-color: black;
  border: 1px solid white;
  color: white;
  border-radius: 5px;
  display: grid;
  grid-template-rows: 12% 78% 10%;
  overflow: hidden;
  padding-bottom: 10px;
  ::-webkit-scrollbar {
    width: 5px;
    background: var(--gray-7);
    border-radius: 25px;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 25px;
  }
}

.grid-section:hover .cash-target-btn-group {
  opacity: 1;
}

.grid-title {
  font-weight: bold;
  margin-bottom: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
  background-color: var(--gray-8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading-Locked {
  color: #74feff;
  opacity: 0.75;
}

.heading-High {
  color: #6ed801;
  opacity: 0.75;
}

.heading-Low {
  color: #ffbf00;
  opacity: 0.75;
}

.heading-Potential {
  color: #ff85ff;
  opacity: 0.75;
}

.grid-title:hover {
  opacity: 1;
  color: black;
}
.heading-Locked:hover,
.active-Locked,
.active-Locked:hover {
  background-color: #74feff;
}

.heading-High:hover,
.active-High,
.active-High:hover {
  background-color: #6ed801;
}

.heading-Low:hover,
.active-Low,
.active-Low:hover {
  background-color: #ffbf00;
}

.heading-Potential:hover,
.active-Potential,
.active-Potential:hover {
  background-color: #ff85ff;
}

.active-Locked,
.active-High,
.active-Low,
.active-Potential {
  color: black;
  opacity: 1;
}

.grid-row-container {
  padding: 10px;
  overflow-y: auto;
}

.grid-row {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid transparent;
  border-radius: 5px;
  transition: border 0.3s;
  cursor: pointer;
}

.grid-row:hover {
  border: 1px solid white;
}
