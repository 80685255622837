.back_btn {
    background-color: var(--primary-color);
    border-radius: 50%;
    height: 35px;
    width: 35px;
    cursor: pointer;
    margin-right: 20px;
}

/* .back_btn:hover {
    color: white
} */