.gantt_chart {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

/* .gantt-cell:hover .add-entry-btn {
  display: block;
} */

/* .add-entry-btn {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

/* .project-color {
  width: 20px;
  height: 20px;
  margin-right: 10px;
} */

/* Modal Styles */
/* .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
} */

/* .modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px;
} */

/* .modal-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
} */

/* .modal-actions button {
  margin-left: 10px;
} */

/* Gantt Chart styles */
.gantt-chart-header-container,
.gantt-chart-data {
  display: grid;
  grid-template-columns: 120px repeat(5, 1fr);
  /* margin-bottom: 20px; */
  /* padding: 32px; */
}
.gantt-chart-header-container {
  margin-bottom: 2.8rem;
}

/* required */
.gantt_header {
  background-color: black;
  color: white;
  /* text-align: center; */
  /* padding: 10px; */
}
.gantt_header-days {
  height: fit-content;
}
/* required */
.gantt_header-users {
  background-color: red;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gantt-cell {
  background-color: #f9f9f9;
  cursor: pointer;
  min-height: fit-content;
  border-right: 3px solid #ddd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Modal styles */
/* .modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
} */

/* .modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
  max-width: 90%;
} */

/* Form styles */
/* .add-user-form,
.add-project-form {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
} */

/* input[type='text'] {
  flex: 1;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 3px;
}

input[type='color'] {
  width: 50px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 3px;
} */

.gannt_chart_container {
  display: grid;
  grid-template-columns: 10% 90%;
  min-height: 95dvh;
  background-color: black;
  color: whitesmoke;
}
.flex_container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.flex-1 {
  flex: 1;
}
/* required */
.vertical_text_container,
.gantt_header-days {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  position: relative;
}
.gantt_header-users-container {
  display: flex;
  justify-content: center;
  padding: 20px;
}
.gantt_header-days {
  width: 100%;
}
/* required */
.vertical_text {
  writing-mode: vertical-lr;
  /* display: inline-block; */
  rotate: 180deg;
}
.progress-container {
  padding: 20px 10px 0 10px;
  border-right: 2px solid white;
}
.text-black {
  color: black;
}

.progress-container .add_progress_btn {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.3s, opacity 0.3s ease-in-out;
}
.progress-container:hover .add_progress_btn {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}
.add_progress_btn {
  background-color: yellow;
  color: black;
  font-size: 28px;
  font-weight: 600;
  padding: 10px 16px 14px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

/* (A) GANTT CHART CONTAINER */
.gantt,
.gantt_data {
  /* (A1) GRID LAYOUT - 7 COLUMNS */
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));

  /* (A2) "TIMELINE"  */
  background: repeating-linear-gradient(
    to left,
    #ffffff,
    #000000 2px,
    #000000 2px,
    #000000 16.66%
  );
  gap: 5px;
}
.gantt_data {
  padding-bottom: 28px;
}
.gantt_head {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  margin-bottom: 16px;
}
/* (B) CELLS */
/* (B1) SHARED CELLS */

/* (B2) HEADER CELLS */
.gantt .head {
  text-align: center;
  font-weight: 700;
  color: #fff;
  background: #000000;
  margin: 5px 20px;
}
.head, .text_center {
  text-align: center;
}
.text_black{
  color: black;
}

.user_image {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 0 auto;
}

.progress {
  border-radius: 36px;
  margin: 5px 5px;
}
