.ts{
    margin: 1rem 0;
}
.ts h1{
    font-weight: 500;
    font-size: 1.6rem;
    color: var(--white);
}
.ts p{
    font-weight: 500;
    color: var(--gray-5);
    margin: 1rem 0;
}
.v_event .transaction{
    margin: 1.5rem 0;
}
.transaction_details .ts{
    width: 100%;
}