.progress-holder {
  height: fit-content;
  margin: 0 0;
  display: grid;
  grid-template-columns: 5% 95%;
  margin-bottom: 25px;
  place-items: center;
  padding: 0 2rem;
}

.progress-header {
  font-size: 18px;
  width: 100%;
  margin-bottom: 2rem;
}

.progress-container {
  width: 100%;
  margin-bottom: 5px;
  border-right: 0;
}

.progress-bar-container {
  position: relative;
  height: 20px;
  border: 1.5px solid #ddd;
  border-radius: 25px;
  margin-bottom: 10px;
  background-color: var(--gray-7);
  display: flex;
  align-items: center;
}

.progress-bar {
  position: absolute;
  height: 100%;
  border-radius: 25px;
}

.progress-labels,
.max-amount {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.max-amount {
  justify-content: flex-end;
}
