.scale-line {
  width: 2.5px;
  height: 1.4rem;
  background-color: gray;
  position: relative;
  bottom: 1rem;
}
.target-line {
  position: absolute;
  height: 100%;
  width: 2px;
  background-color: red;
  top: 0;
  border-radius: 1px;
}
.target-amount {
  position: absolute;
  width: auto;
  font-size: 1.2rem;
  top: -25px;
  color: red;
  pointer-events: none;
}

.locked-progress {
  background: #74feff;
}

.high-progress {
  background: #6ed801;
}

.low-progress {
  background: #ffbf00;
}

.potential-progress {
  background: #ff85ff;
}

.amount-zone {
  position: absolute;
  z-index: 10;
}

.amount-zone {
  position: absolute;
  bottom: -5.5rem;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 5px;
  font-size: 1rem;
  border-radius: 5px;
  white-space: nowrap;
  pointer-events: none;
}

