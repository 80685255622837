.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.text-white {
  color: whitesmoke;
}

.cash_target {
  font-size: 18px;
  margin-bottom: 20x;
}

.switch-cash-target-revenue,
.filter-year-month,
.add_cash_target {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}
.switch-cash-target-revenue {
  justify-content: unset;
}
.filter-year-month {
  gap: 2rem;
  justify-content: center;
}

.filter-year-month .filter_select {
  text-align: center;
  padding: 1.2rem 0;
}

.add_cash_target button svg,
.switch-month-btn-group button svg {
  color: gray;
  opacity: 0.5;
}
.add_cash_target button svg:hover,
.switch-month-btn-group button svg:hover {
  color: whitesmoke;
  opacity: 1;
}

.btn_create span {
  margin-top: 0px;
}

.cash_target .back_btn {
  display: none;
}

.cash-target-btn-group {
  width: 100%;
  margin: auto;
  padding: 0 1rem;
  border-radius: 1rem;
  gap: 5px;
  justify-content: space-around;
  display: flex;
  opacity: 0.1;
  transition: all 0.5s ease-in-out;
}

.cash-target-btn-group button {
  padding: 5px;
  border-radius: 0.5rem;
  border: 1px solid white;
  gap: 0.5rem;
  width: 100%;
  justify-content: center;
}
.cash-target-btn-group button span {
  font-size: 14px;
}

.cash-target-data_table_inner {
  position: relative;
}
