.attendance_select {
  display: flex;
  align-items: center;
  /* margin-left: 2rem; */
}

.attendance_select label {
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.9rem;
  letter-spacing: -0.02em;
  color: #fff;
}

.attendance_select select {
  min-width: unset;
  width: fit-content;
  margin-left: 0rem;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9rem;
  letter-spacing: -0.02em;
  color: #adacac;
  padding: 1rem 1.5rem;
  padding-right: 3.6rem;
  background-color: transparent;
  border: 2px solid var(--primary-color);
  box-sizing: border-box;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1.8rem;
  cursor: pointer;
  option {
    color: black;
  }
}

.attendance_select select:focus {
  border-color: var(--gray-3);
  box-shadow: none;
}
