.screen_wrapper {
  display: grid;
  grid-auto-rows: 10% 90%;
  height: 100%;
  width: 100%;
}

.screen_header {
  align-items: center;
  padding: 2.2rem 4.4rem 2.6rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  background-color: var(--black);
  border-radius: 1rem;
}

.options_container {
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.data_table_wrapper {
  padding-top: 2.5rem;
}

.data_table_inner {
  background-color: var(--black);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 3.3rem 4.4rem 4.4rem;
  width: 100%;
}

.data_table {
  border-collapse: initial;
  display: grid;
  grid-template-rows: 10% 80% 10%;
  height: 100%;
  table-layout: fixed;
  width: 100%;
}

.data_table tbody tr,
.data_table thead {
  border-collapse: initial;
  display: table;
  table-layout: fixed;
  width: 100%;
}

.data_table td:not(:last-child),
.data_table th:not(:last-child) {
  text-align: left;
}

.data_table th {
  color: var(--primary-color);
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 2.8rem;
  padding-bottom: 2rem;
}

.ss_category {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
}

.ss_category .s_client {
  width: 200px;
}

.ss_category .input_field {
  margin-bottom: 0px;
  padding: 1.2rem 2.4rem;
}

.screen_header .search_input {
  margin-bottom: 0px;
  padding: 1.2rem 2.4rem;
}

.data_table td,
.data_table th {
  padding-left: 3.6rem;
  padding-right: 3.6rem;
}

.data_table td:last-child,
.data_table th:last-child {
  text-align: right;
}

.data_table tbody {
  -ms-overflow-style: none;
  display: block;
  overflow-y: scroll;
  scrollbar-width: none;
}

.project_btn {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: flex-end;
}

.btn_create svg {
  width: 3rem;
  height: 3rem;
  color: var(--primary-color);
  cursor: pointer;
}

.attendance_select_container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.story_epic {
  color: #e6e6e6;
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 2rem;
}

.view_btn_container {
  display: flex;
  gap: 2rem;
  align-items: center;
  flex-wrap: wrap;
}

.text {
  color: #e6e6e6;
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 2rem;
}

.project_client {
  display: flex;
  align-items: center;
  /* grid-template-columns: 50% 50%; */
  gap: 20px;
  margin-bottom: 20px;
}

.project_client img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.edit,
.archive {
  color: white;
  padding: 10px 15px;
  width: 200px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
}

.edit {
  border: 1px solid white;
  background-color: black;
}

.archive {
  border: 1px solid red;
  background-color: red;
}

.rounded_image {
  max-width: 6rem;
}

.rounded_image img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  object-fit: contain;
  background-color: var(--black);
  border: 1px solid var(--white);
  /* object-position: 0 20%; */
}
