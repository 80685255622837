.screen_wrapper .cash_report_screen_header {
  display: flex;
}
.cash_report_filter .attendance_select select {
  padding-right: 1.6rem;
}

/* .cash-report-tbody {
  position: relative;
} */
.data_table {
  position: relative;
}

.cash-report-row {
  cursor: pointer;
  border: 1px solid transparent;
  &:hover,
  &:focus {
    border: 1px solid var(--primary-color);
    border-radius: 1rem;
  }
}
.cash-report-tbody :nth-child(2) {
  margin-top: 8rem;
}
.cash-report-row :last-child {
  margin-top: 10rem;
}
.cash-report-row td,
.cash-report-initial-balance td,
.cash-report-ending-balance td {
  padding: 20px;
}
.cash-report-initial-balance,
.cash-report-ending-balance {
  position: absolute;
  background: #000;
  z-index: 999;
}
.cash-report-ending-balance {
  bottom: 0;
}
.cash-report-initial-balance td,
.cash-report-ending-balance td {
  font-weight: 800;
  color: antiquewhite;
}
.negative-amount {
  color: #eb9183 !important;
}
.positive-amount {
  color: #83ebb4 !important;
}
