.filter_container {
  display: flex;
  align-items: center;
  max-width: 200px;
  border: 1px solid transparent;
}

.filter_select {
  width: 200px;
  color: #999999;
  background: rgba(26, 26, 26, 0.9);
  padding: 1.2rem 2.4rem;
  border-radius: 5px;
  cursor: pointer;
}

.filter_container:hover {
  border: 1px solid var(--gray-5);
  border-radius: 5px;
}
