.entries-container {
  position: relative;
  min-height: 100dvh;
}

.switch-month-btn-group {
  position: absolute;
  display: flex;
  width: 108%;
  justify-content: space-between;
  margin: auto;
  right: -4%;
  top: 45%;
}
.switch-month-btn-group button {
  z-index: 10;
}
.switch-month-btn-group button svg {
  height: 4rem;
  width: 4rem;
}
